<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] overflow-y-auto">
                <div class="w-[calc(100vw-12vw)] mx-auto text-black">
                    <div class="pb-[30px]">

                        <!-- Image box -->
                        <div class="flex justify-center py-3">
                            <img v-show="!status" class="h-16 w-16 flex-none rounded-full bg-gray-50" src="/img/clocks.png" alt="">
                            <img v-show="status" class="h-16 w-16 flex-none rounded-full bg-gray-50" src="" alt="商品图片">
                        </div><!-- Image box -->
                        
                        <!-- main box -->
                        <div class="pt-[10px] flex justify-center">
                                <div class="flex items-center mx-auto">
                                    <!-- <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg> -->
                                    <h2 class="pb-2 text-xs pt-2  font-semibold text-[#ea580c]">
                                        <span v-show="!status">{{ $t('text.WaitingForAllocation') }}</span>
                                        <span v-show="status">商品名称</span>
                                    </h2>
                                </div>
                        </div><!-- /end main box-->

                  
                        <div class="border border-zinc-300 border-dashed my-3"></div>

                        <div class="flex justify-between items-center text-xs-xs text-orange-600">
                            <div class="">{{ $t('pageTxt.orderDetail.orderValue') }}</div>
                            <div class="">{{ amount }}&nbsp;VND</div>
                        </div>

                        <div class="flex justify-between items-center text-xs-xs text-orange-600">
                            <div class="">{{ $t('pageTxt.orderDetail.profitMoney') }}</div>
                            <div class="">{{ commission }}&nbsp;VND</div>
                        </div>

                        <div class="flex justify-between items-center text-xs-xs text-orange-600">
                            <div class="">{{ $t('pageTxt.orderDetail.amountToBeReturned') }}</div>
                            <div class="">{{returned}}&nbsp;VND</div>
                        </div>

                        <div class="flex justify-between items-center text-xs-xs text-orange-600">
                            <div class="">{{ $t('pageTxt.orderDetail.codeOrders') }}</div>
                            <div class="">{{number}}</div>
                        </div>

                        <div class="flex justify-between items-center text-xs-xs text-orange-600">
                            <div class="">{{ $t('pageTxt.orderDetail.receivingTime') }}</div>
                            <div class="">{{ date(times) }} &nbsp; {{ time(times) }}</div>
                        </div>

                        <div class="flex justify-between items-center text-xs-xs text-orange-600">
                            <div class="">{{ $t('pageTxt.orderDetail.orderStatus') }}</div>
                            <div>
                                <span v-show="0==status">{{ $t('text.WaitingForAllocation') }}</span>
                                <span v-show="2==status">{{ $t('pageTxt.order.tab2') }}</span>
                                <span v-show="4==status">{{ $t('pageTxt.order.tab3') }}</span>
                                <span v-show="3==status">{{ $t('pageTxt.order.tab4') }}</span>
                            </div>
                        </div>


                        <div v-show="commodity_id && 2==status" class="mt-5">
                            <button
                                class="bg-gradient submit-button px-1 py-1 rounded-full  text-white focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
                                @click="onSubmit"
                            >
                                {{ $t('pageTxt.orderDetail.submit') }}
                            </button>
                        </div>

                    </div>
                </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/mobile/MainMenu.vue"
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import format from '@/assets/js/formatDate'

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        const formatFunc = format()
        const route = useRoute()
        let orders_id = route.query.orders_id
        let status = route.query.status
        let number = route.query.number
        let times = route.query.date
        let amount = route.query.amount
        let commission = route.query.commission
        let returned = parseInt(commission) + parseInt(amount)
        let commodity_id = route.query.commodity_id
        return {title, ...formatFunc, orders_id, status, number, times, amount, commission, returned, commodity_id}
    },
    data() {
        return {
            lang: '',
            api: {
                grade: '',
                agent: '',
                balance: '',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.product') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    },
    methods: {        
        test(){
            console.log( this.number )
        },
        // 得到这个用户的基本信息
        // 需要知道这个用户 当前的级别 完成任务的量 当前余额等信息
        getInfo()
        {
            // console.log('得到这个用户的基本信息')
            axios.post(
                '/api/m/info/user', 
                {
                    
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    this.api.balance = response.data.data.balance
                    this.api.grade = response.data.data.grade
                    let vip = response.data.data.grade
                    if (1===vip) this.api.agent = '6'
                    if (2===vip) this.api.agent = '5'
                    if (3===vip) this.api.agent = '4'
                    if (4===vip) this.api.agent = '3'
                    if (5===vip) this.api.agent = '2'
                    if (6===vip) this.api.agent = '1'
                } else {
                    layer.msg(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }, // End 得到这个用户的基本信息
        // 提交提现申请
        onSubmit() {
            axios.post(
                '/api/m/pay/order', 
                {
                    orders_id: this.orders_id
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    this.status = 3
                    // this.$router.go(0) // 刷新本页

                     this.$router.push({ path: '/order/detail', query: { 
                                        orders_id: this.orders_id,
                                        status: 3,
                                        number: this.number,
                                        times: this.times,
                                        amount: this.amount,
                                        commission: this.commission,
                                        commodity_id: this.commodity_id,
                                    }})

                    

                } else {
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()

    }
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}

.top-page-bg {
  background-image: url('/public/img/clocks.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% auto; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
  background-color: #FFFFFF;
}
</style>