// 进口路由
import { createRouter, createWebHistory } from "vue-router"
// 进口仪表板视图模板


// 登录后的模板
import OrderList from '../pages/OrderList'
import MainProduct from '@/pages/MainProduct'
import UserRevision from '../pages/UserRevision'
import UserGuide from '../pages/UserGuide'
import CompanyProfile from '../pages/CompanyProfile'
import UserServe from '../pages/UserServe'
import OnlineService from '../pages/OnlineService'
import FinancialDetails from '../pages/FinancialDetails'
import UserRecharge from '../pages/UserRecharge'
import UserRecharge2 from '../pages/UserRecharge2'
import UserWithdraw from '../pages/UserWithdraw'
import EditPassword from '../pages/EditPassword'
import WithdrawalPassword from '../pages/WithdrawalPassword'
import WithdrawalAccount from '../pages/WithdrawalAccount'
// 抢单成功
import OrderSuccess from '../pages/OrderSuccess'
// 订单详情
import DetailOrder from '../pages/DetailOrder'
// 电脑端首页
import computerHome from '@/pages/computer/IndexHome'
// 手机端首页
import home from '@/pages/mobile/IndexHome'
// 电脑端登录页
import computerLogin from '@/pages/computer/LoginUser'
// 手机端登录页
import login from '@/pages/LoginUser'
// 电脑端注册页
import computerRegister from '@/pages/computer/RegisterUser'
// 手机端注册页
import register from '../pages/RegisterUser'
// 电脑端用户页
import computerUser from '@/pages/computer/UserMain'
// 手机端用户页
import user from '@/pages/UserMain'
// 电脑端发布付费文章
import computerPublishArticle from '@/pages/computer/PublishArticle'
// 电脑端发布付费视频
import computerPublishVideo from '@/pages/computer/PublishVideo'
// 电脑端播放视频页面
import computerPlayVideo from '@/pages/computer/PlayVideo'


import i18n from '../language'


const routes = [
    {
        name: 'Home',
        // 路由的 URL 字串
        path: '/',
        component: home,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            // 如果用户还没有指定语言 localStorage.getItem("lang") 即它没有值 则使用语言包中定义的默认语言 i18n.global.locale.value
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['order']
        }
    },
    {
        name: 'computerHome',
        // 路由的 URL 字串
        path: '/computer',
        component: computerHome,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['order']
        }
    },
    {
        name: 'Login',
        // 路由的 URL 字串
        path: '/login',
        component: login,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['side']['main']
        }
    },
    {
        name: 'computerLogin',
        // 路由的 URL 字串
        path: '/computer/login',
        component: computerLogin,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['side']['main']
        }
    },
    {
        name: 'Register',
        path: '/register',
        component: register,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['side']['main']
        }
    },
    {
        name: 'computerRegister',
        path: '/computer/register',
        component: computerRegister,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['side']['main']
        }
    },
    {
        name: 'MainProduct',
        path: '/product',
        component: MainProduct,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['product']
        }
    },
    {
        name: 'User',
        path: '/user',
        component: user,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['side']['main']
        }
    },
    {
        name: 'computerUser',
        path: '/computer/user',
        component: computerUser,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['side']['main']
        }
    },
    {
        name: 'Order',
        path: '/order',
        component: OrderList,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['order']
        }
    },
    {
        name: 'UserRevision',
        path: '/revision',
        component: UserRevision,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['revision']
        }
    },
    {
        name: 'UserGuide',
        path: '/guide',
        component: UserGuide,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['guide']
        }
    },
    {
        name: 'CompanyProfile',
        path: '/company/profile',
        component: CompanyProfile,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['companyProfile']
        }
    },
    {
        name: 'UserServe',
        path: '/serve',
        component: UserServe,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['serve']
        }
    },
    {
        name: 'OnlineService',
        path: '/online/service',
        component: OnlineService,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['onlineService']
        }
    },
    {
        name: 'FinancialDetails',
        path: '/financial/details',
        component: FinancialDetails,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['financialDetails']
        }
    },
    {
        name: 'UserRecharge',
        path: '/recharge',
        component: UserRecharge,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['recharge']
        }
    },
    {
        name: 'UserRecharge2',
        path: '/recharge2',
        component: UserRecharge2,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['recharge']
        }
    },
    {
        name: 'UserWithdraw',
        path: '/withdraw',
        component: UserWithdraw,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['withdraw']
        }
    },
    {
        name: 'EditPassword',
        path: '/edit/password',
        component: EditPassword,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['password']
        }
    },
    {
        name: 'WithdrawalPassword',
        path: '/withdraw/password',
        component: WithdrawalPassword,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['withdraw']
        }
    },
    {
        name: 'WithdrawalAccount',
        path: '/withdraw/account',
        component: WithdrawalAccount,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['withdraw']
        }
    },
    {
        name: 'OrderSuccess',
        path: '/order/success',
        component: OrderSuccess,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['OrderSuccess']
        }
    },
    {
        name: 'DetailOrder',
        path: '/order/detail',
        component: DetailOrder,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['OrderDetail']
        }
    },
    // 电脑端发布付费文章
    {
        name: 'computerPublishArticle',
        path: '/computer/publish/article',
        component: computerPublishArticle,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['PublishArticle']
        }
    },


    // 电脑端发布付费视频
    {
        name: 'computerPublishVideo',
        path: '/computer/publish/video',
        component: computerPublishVideo,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['PublishVideo']
        }
    },

    
    // 电脑端播放视频
    {
        name: 'computerPlayVideo',
        path: '/computer/play/video',
        component: computerPlayVideo,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? i18n.global.locale.value ]['page']['PublishVideo']
        }
    },

]

const router = Router()
export default router
function Router() {
    // console.log( localStorage.getItem("lang") ) 
    // console.log( i18n.global.locale.value )
    const router = new createRouter({
        history: createWebHistory(),
        routes
    })
    return router
}