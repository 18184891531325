<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] overflow-y-auto ">
            <div class="w-[calc(100vw-12vw)] mx-auto  text-black">
                <!-- main box -->
                
                <div v-show="show.setup1">
                    <div class="my-5">
                        <h1 class="text-xs-xs font-semibold text-slate-900 pl-5 text-left">{{ $t('page.recharge') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-5 py-2 my-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            :placeholder="$t('placeholder.rechargeAmount')"
                            v-model="input.amount"

                        />
                    </div>

                    <div class="mt-0 p-4">
                        <button
                            v-show="sendBut"
                            class="submit-button px-1 py-1 rounded-full bg-orange-500 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
                            @click="onSubmit"
                        >
                            {{ $t('but.continue') }}
                        </button>
                    </div>
                </div>




                <div v-show="show.setup2">
                   
                    <div class="my-5">
                        <h1 class="text-xs-xs  pl-5 font-semibold text-slate-700 text-left">{{ $t('revision.bankAccount') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            placeholder=""
                            v-model="bank.number"
                            disabled
                        />
                    </div>
                    <div class="my-5">
                        <h1 class="text-xs-xs  pl-5 font-semibold text-slate-700 text-left">{{ $t('revision.userNAME') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            placeholder=""
                            v-model="bank.name"
                            disabled
                        />
                    </div>
                    <div class="my-5">
                        <h1 class="text-xs-xs  pl-5 font-semibold text-slate-700 text-left">{{ $t('revision.bankName') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            placeholder=""
                            v-model="bank.title"
                            disabled
                        />
                    </div>
                    <div class="my-5">
                        <h1 class="text-xs-xs  pl-5 font-semibold text-slate-700 text-left">{{ $t('revision.rechargeAmount') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            placeholder=""
                            v-model="input.amount"
                            disabled
                        />
                    </div>
                    <div class="my-2">
                        <span class="text-xs">{{$t('text.tipRecharge')}}</span>
                    </div>
                    <div class="mt-0 p-4">
                        <router-link to="/user">
                            <button
                                
                                class="submit-button px-1 py-1 rounded-full bg-orange-500 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
                            >
                                {{ $t('revision.CompleteRecharge') }} 
                            </button>
                        </router-link>
                    </div>
                </div>

                <!-- /end main box-->
            </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/mobile/MainMenu.vue"
import { ref } from 'vue'

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            sendBut: true,
            input: {
                amount: ''
            },
            show: {
                setup1: true,
                setup2: false,
            },
            bank: {
                number: '', 
                name: '',
                title: '',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        
        this.title = this.$t('page.recharge') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    },
    methods: {
        onSubmit() {
            // 点击提交时 会向后端发起创建充值订单的请求 服务器响应慢时 用户可能会多次点击 所以 当用户发起充值请求时 隐藏掉这个充值按钮
            this.sendBut = false
            // 后端生成一个充值订单 返回一个银行卡号 订单状态为等待用户付款状态
            // 等待用户充值 提示用户完成银行转帐之后 
            // 提示的页面 出现待完成的支付订单 可以删除
            axios.post(
                '/api/m/create/recharge', 
                {
                    amount: this.input.amount,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                console.log(response.data)
                if (0===response.data.code) {
                    // 不要跳转，就要本页面显示，接收充值的银行卡信息。
                    this.show.setup1 = false
                    this.show.setup2 = true
                    let banks = response.data.data.banks
                    this.bank.number = banks.number
                    this.bank.name = banks.name 
                    this.bank.title = banks.title 
                    console.log( this.bank )
                } else {
                    let word = response.data.data.id
                    let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })

        }
    }
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
</style>