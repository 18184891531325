<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px]  overflow-y-auto">
            <div class="page-bg-main-product"> <!-- 放背景图 -->
                <div class="w-[calc(100vw-12vw)] mx-auto text-black">
                    <div class="pb-[30px]">
                        <!-- main box -->
                        <div class="pt-[320px] ">

                            <h2 class="mb-2  text-xs-xs pt-3  text-lg font-semibold text-[#ea580c] ">Bộ sưu tập Kho báu</h2>
                            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400  text-left text-xs-xs ">
                                <li class="flex items-center">
                                    <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    Cùng nhau kiếm tiền hoa hồng
                                </li>
                                <li class="flex items-center">
                                    <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    Ngày qua ngày Kiếm tiền
                                </li>
                                <li class="flex items-center">
                                    <svg class="w-3.5 h-3.5 me-2 text-gray-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    Hôm nay vẫn còn cơ hội đặt hàng
                                </li>
                            </ul>

                        </div>

                        <div>
                            <h2 class="mb-2  text-xs-xs pt-3  text-lg font-semibold text-[#ea580c] ">Đại lý cấp {{ api.agent }}</h2>
                            <dl class=" text-left text-xs-xs max-w-md text-gray-900 divide-y divide-slate-200 dark:text-white dark:divide-slate-200">
                                <div class="flex flex-col pb-2">
                                    <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Thành viên áp dụng</dt>
                                    <dd class="text-xs-xs font-semibold text-[#71717a] dark:text-[#71717a]">Thành viên áp dụng VIP1 trở lên</dd>
                                </div>
                                <div class="flex flex-col py-2">
                                    <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Phạm vi số lượng</dt>
                                    <dd class="text-xs-xs font-semibold text-[#71717a] dark:text-[#71717a]">1000 VND To 99999999999VND</dd>
                                </div>
                                <div class="flex flex-col pt-2">
                                    <dt class="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Hoa hồng</dt>
                                    <dd class="text-xs-xs font-semibold text-[#71717a] dark:text-[#71717a]">19% tổng số vốn đặt hàng</dd>
                                </div>
                            </dl>

                        </div>
                    

                        <div>

                            <h2 class="mb-2  text-xs-xs pt-3  text-lg font-semibold text-[#ea580c] ">Tài khoản của tôi</h2>

                            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400  text-left text-xs-xs ">
                                <li class="flex items-center">
                                    <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    <span class="text-xs-xs">Cấp độ thành viên：<span class=" text-[#0062bf] dark:text-[#0062bf] text-xs-xs">VIP{{ api.grade }}</span></span>
                                </li>
                                <li class="flex items-center">
                                    <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    <span class="text-xs-xs">Giới hạn cập nhật：<span class=" text-[#0062bf] dark:text-[#0062bf] text-xs-xs">0/15</span></span>
                                </li>
                                <li class="flex items-center">
                                    <svg class="w-3.5 h-3.5 me-2 text-gray-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    <span class="text-xs-xs">Số tiền còn lại：<span class=" text-[#0062bf] dark:text-[#0062bf] text-xs-xs">{{ api.balance }} VND</span></span>
                                </li>
                            </ul>


                        </div>

                        <div class="mt-0 pt-8">
                            <button @click="onSubmit"
                                class="submit-button px-1 py-1 rounded-full bg-[#ea580c] text-[#faf5ff] focus:ring focus:outline-none w-full text-xl font-semibold transition-colors">
                                Nhận ngay
                            </button>
                        </div>

                        
                        <h2 class="text-xs-xs pt-3   text-lg font-semibold text-[#ea580c]">Ghi chú :</h2>
                        <ul class="max-w-md space-y-1 text-left text-gray-500 list-disc list-inside dark:text-gray-400">
                            <li class="text-xs-xs font-semibold text-[#9ca3af] dark:text-[#9ca3af]">
                                Tất cả Đơn hàng sẽ được hệ thống eshop tự động phân phối ngẫu nhiên !
                            </li>
                            <li class="text-xs-xs font-semibold text-[#9ca3af] dark:text-[#9ca3af]">
                                Giới hạn đơn hàng tùy thuộc vào cấp bậc thành viên của bạn.
                            </li>
                            <li class="text-xs-xs font-semibold text-[#9ca3af] dark:text-[#9ca3af]">
                                Vốn lưu động trong tài khoản của bạn cần có tương đương với giá trị đơn hàng mới có thể xử lí đơn.
                            </li>
                        </ul>



                    <!-- /end main box-->
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/mobile/MainMenu.vue"
import { ref } from 'vue'

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {


        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            api: {
                grade: '',
                agent: '',
                balance: '',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.product') // 重置 setup 中的 title 要在重设置了语言之后
        this.getInfo()
        return {}
    },
    methods: {        
        // 得到这个用户的基本信息
        // 需要知道这个用户 当前的级别 完成任务的量 当前余额等信息
        getInfo()
        {
            // console.log('得到这个用户的基本信息')
            axios.post(
                '/api/m/info/user', 
                {
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    this.api.balance = response.data.data.balance
                    this.api.grade = response.data.data.grade
                    let vip = response.data.data.grade
                    if (1===vip) this.api.agent = '6'
                    if (2===vip) this.api.agent = '5'
                    if (3===vip) this.api.agent = '4'
                    if (4===vip) this.api.agent = '3'
                    if (5===vip) this.api.agent = '2'
                    if (6===vip) this.api.agent = '1'
                } else {
                    // layer.msg(response.data.msg)
                    localStorage.removeItem('token')
                    // this.$router.go(0) // 刷新本页
                    // 跳转
                    this.$router.push({
                        path: '/login',
                        query: {
                            msg: 'account out'
                        }
                    })
                }
            })
            .catch(function (error) {
                // 没有权限就跳到登录页
                console.log(error)
            })
        }, // End 得到这个用户的基本信息
        // 提交提现申请
        onSubmit() {
            axios.post(
                '/api/m/rush/to/buy', 
                {

                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    this.$router.push({
                        path: '/order/success',
                        query: {
                            msg: 'order success'
                        }
                    })
                } else {
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()

    }
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}

.page-bg-main-product {
  background-image: url('/public/img/product.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% auto; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
  background-color: #FFFFFF;
}
</style>