<template>
    <!-- 固定到顶部加上 fixed top-0  -->
    <div class="fixed top-0 h-[44px] z-50 bg-white">
        <div class="flex justify-between items-center px-[16px] py-[7px] h-[30px] w-[343px]">
            <div class="text-ms w-[30px] h-[30px] py-[7px]">
                <router-link :to="back">
                    <svg class="w-[30px] h-[30px]"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="#283643" d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z"/>
                    </svg>
                </router-link>
            </div>
            <div class="text-ms w-[30px] h-[30px] py-[7px]">
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 定义在这里方便查找与变更 
            // 直接写死在 HTML 中比较难于查找
            back: '/computer',
        }
    },
}
</script>

<style>
    .bark {color: rgb(13, 194, 83)}
</style>