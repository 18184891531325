<template>
    <div id="computer">
        <topBox :title="title" />
        <div class="px-[16px] w-[375px] pt-[44px] pb-[65px] mt-[44px] mb-[55px] mx-auto overflow-y-auto bg-local">

            <div class="w-[345px] p-[20px]">
                <div class="text-ms text-right text-red-700" v-show="err.title">
                    {{ msg.errTitle }}
                </div>
                <div class="flex">
                    <button class="w-[100px] px-[14px] py-[12px] text-right z-10   text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                        文章标题
                    </button>
                    <input
                        name="title"
                        type="text"
                        class="w-[245px] px-[14px] py-[1px] outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  block border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                        :placeholder="msg.title"
                        v-model="form.title"
                    />
                    <!-- disabled -->
                </div>
            </div>

            <div class="w-[343px] p-[20px]">
                <div class="text-ms text-right text-slate-400" v-show="err.image">
                    {{ err.image }}
                </div>
                <div class="text-ms text-right text-red-700" v-show="err.image">
                    {{ err.image }}
                </div>
                <div class="flex z-0">
                    <button class="w-[100px] px-[14px] py-[12px]  text-right  text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                        上传封面
                    </button>
                    <input
                        name="icon"
                        type="file"
                        @change="selectCover"
                        class="w-[215px] px-[14px] py-[10px] outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  block   border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700 "
                    />
                    <!-- disabled -->
                </div>
                <p class="text-ms" v-if="uploadCover">{{ uploadCoverText }}</p>
            </div>


            <div class="w-[343px] p-[20px]">
                <div class="text-ms text-right text-red-700" v-show="err.amount">
                    {{ msg.errTitle }}
                </div>
                <div class="flex">
                    <button class="w-[100px] px-[14px] py-[12px] text-right z-10  text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                        收费金额
                    </button>
                    <input
                        name="amount"
                        type="text"
                        class="w-[245px] px-[14px] py-[1px] outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms block  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                        :placeholder="msg.amount"
                        v-model="form.amount"
                        
                    />
                    <!-- disabled -->
                </div>
                <p class="text-ms">如果不设置金额视频将免费提供给用户观看</p>
            </div>

            <div class="w-[343px] p-[20px]">
                <div class="text-ms text-right text-slate-400" v-show="err.video">
                    {{ err.video }}
                </div>
                <div class="text-ms text-right text-red-700" v-show="err.video">
                    {{ err.video }}
                </div>
                <div class="flex z-0">
                    <button class="w-[100px] px-[14px] py-[12px]  text-right  text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                        上传视频
                    </button>
                    <input
                        name="icon"
                        type="file"
                        @change="selectVideo"
                        class="w-[215px] px-[14px] py-[10px] outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  block   border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700 "
                    />
                    <!-- disabled -->
                </div>
                <p class="text-ms" v-if="uploadVideo">{{ uploadVideoText }}</p>
            </div>


            <div class="w-[345px] p-[20px]">
                <div class="text-ms text-right text-red-700" v-show="err.second">
                    {{ msg.errSecond }}
                </div>
                <div class="flex">
                    <button class="w-[100px] px-[14px] py-[12px] text-right z-10   text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                        收费时间
                    </button>
                    <input
                        name="title"
                        type="text"
                        class="w-[245px] px-[14px] py-[1px] outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  block border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                        :placeholder="msg.tipSecond"
                        v-model="form.second"
                    />
                    <!-- disabled -->
                </div>
                <p class="text-ms">0.5 表示开始前半分钟内不收费</p>
            </div>

            <div class="w-[345px] p-[20px]">
                <button
                    class="block w-full px-5  py-[15px] border  bg-blue-500 text-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-ms"
                    @click="runSubmit"
                >
                    {{ $t('but.continue') }}
                </button>
            </div>

        </div>
        <mainMenu />  
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import topBox from "@/components/computer/TopBox.vue"
import mainMenu from "@/components/computer/MainMenu.vue"
import axios from 'axios'
import { layer } from "vue3-layer"
// 不用编辑器就不要加载 01
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default {
    components: {
        topBox,
        mainMenu,
        // QuillEditor, 不用编辑器就不要加载 02
    },
    setup() {
        // console.log( `token : ${localStorage.getItem('token')}` )
        // if ( !localStorage.getItem('token') ) {
        //     console.log('连令牌都没有，还不去登录？')
        // }
    },
    data() {
        return {
            title: this.$t('page.PublishArticle'),
            lang: '',
            api: {
                account: null,
                uid: 0,
                invite: null,
                bank_id: null,
            },
            err: {
                title: false,
                amount: false,
                image: false,
                video: false,
                second: false,
            },
            tip: {
                title: false,
                amount: false,
                image: false,
                video: false,
                second: false,
            },
            msg: {
                errSort_id: '必须选择',
                tipTitle: '请输入中文的商品分类名称',
                errTitle: '',
                tipTitle_vi: '请输入越文的商品分类名称',
                errTitle_vi: '',
                tipShop: '请输入中文的店铺名称',
                errShop: '',
                tipShop_vi: '请输入越文的店铺名称',
                errShop_vi: '',
                tipPrice: '请输入商品的价格',
                errPrice: '',
                tipIcon: '请选择分类的LOGO',
                errIcon: '',
                tipSecond: '第几分钟开始收费',
                errSecond: '',
            },
            editor: '',
            form: {
                title: '',
                editor: '',  // 文章正文 因为用了编辑器插件
                pay: '',     // 文章正文 该部份为付费部份
                amount: 0,  // 收费金额
                cover: '',   // 视频的封面图
                video: '',
                second: 0.5,  // 在哪个单位开始收费 30 秒一个单位
            },
            uploadCover: false, // uploadCover 控制是否显示上传封面的进度条
            uploadCoverText: '封面图正在上传，请稍候...',
            uploadVideo: false,
            uploadVideoText: '视频正在上传，请稍候...',
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // 通过 Token 去调取帐号相关信息，即时。
        this.getInfo()
        return {}
    },
    methods: {
        selectCover(event) {
            this.form.cover = event.target.files[0]
        },
        selectVideo(event)
        {
            this.form.video = event.target.files[0]
        },
        doClick() {
            // console.log('ok')
        },
        onSubmit() {
            this.$route.to('/recharge')
        },
        // 得到这个用户的基本信息
        getInfo()
        {
            // console.log('得到这个用户的基本信息')
            axios.post(
                '/api/m/info/user', 
                {
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response.data)
                if (0===response.data.code) {
                    this.api.bank_id = response.data.data.bank_id
                    this.api.account = response.data.data.account
                    this.api.grade = `VIP${response.data.data.grade}` 
                    this.api.uid = response.data.data.uid
                    this.api.invite = 999999 - response.data.data.uid
                    this.api.balance = response.data.data.balance
                    this.api.frozen = response.data.data.frozen
                } else {
                    // let word = response.data.data.id
                    // let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // // 转成国际化 后端转提示语 前端转表单名称
                    // let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        // End 得到这个用户的基本信息
        out() {
            // localStorage.removeItem('token')
            axios.post(
                '/api/m/out', 
                {

                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    localStorage.removeItem('token')
                    // this.$router.go(0) // 刷新本页
                    // 跳转
                    this.$router.push({
                        path: '/computer/login',
                        query: {
                            msg: 'account out'
                        }
                    })
                } else {
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }, // End out() 退出系统
        // 提交视频及附属信息
        runSubmit() {
            console.log('提交视频及附属信息')

            axios.post(
                '/api/m/video', 
                {
                    cover: this.form.cover,
                    video: this.form.video,
                    title: this.form.title,
                    amount: this.form.amount,
                    second: this.form.second,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',  // multipart/form-data，以支持文件上传
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    },
                    // 使用onUploadProgress方法来监听上传进度事件 并将进度以百分比的形式存储在uploadProgress属性中
                    onUploadProgress: () => {
                        this.uploadCover = true
                        this.uploadVideo = true
                    },
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    this.uploadCover = false
                    this.uploadVideo = false
                    // 跳转
                    // this.$router.push({
                    //     path: '/computer/login',
                    //     query: {
                    //         msg: 'account out'
                    //     }
                    // })
                } else {
                    // layer 弹窗的颜色与透明度
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }, // End runSubmit() 提交视频及附属信息
    }
}
</script>

<style>
.focus {
    background-image: url('/public/upload/600.png');
    background-repeat: no-repeat; /** 图片不要平铺 */
    background-size: 100% 190px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
.ql-toolbar {
    @apply p-[1px];
    height: auto;
    font-size: 12px;
}
.layui-layer-content{background-color:  #c00; color: #fff;}
</style>