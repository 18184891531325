import { createI18n } from 'vue-i18n'
import zhCN from './locales/zh_CN'
import enUS from './locales/en_US'
import viVN from './locales/vi_VN'

// 这里的 locale 应该由缓存中获取。
const i18n = createI18n({
  legacy: false,
  locale: 'zh-cn', // 默认显示语言
  messages: {
    'zh-cn': zhCN,
    'en-us': enUS,
    'vi-vn': viVN
  }
})

export default i18n

