<template>
    <div class="z-0 marqueePc" id="tipMarquee">
       <Vue3Marquee :vertical="true">
            <div v-for="img in imgArray"
                :key="img"
                class="cardBox"
            >
                <div class="flex justify-between items-center">
                    <div class="text-ms p-1">
                        {{ img.phone }} <br />
                        {{ img.username }} 
                    </div>
                    <div class="text-ms p-1">₫{{ img.balance }}</div>
                </div>
            </div>
        </Vue3Marquee>
    </div>
</template>

<script>
import postPages from "@/assets/js/postPages"
export default {
    components: {
        // Vue3Marquee
    },
    setup() {
    },
    data() {
        return {
            imgArray : []
        }
    },
    mounted() {
        this.getMarquee()
        return {}
    },
    methods: {
        getMarquee()
        {
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/marquee', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {
                    this.imgArray = res.data
                }
            })
        }, // End getMarquee()
    }
}
</script>
<style>
    /* .maxW {max-width: 600px;} */
</style>