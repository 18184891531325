<template>
    <div id="computer">
        <topBox :title="title" />
        <div class="w-[375px]  pb-[65px]  mb-[55px] mx-auto overflow-y-auto bg-local">

          <video-player
            :src="src + '/m3u8/AmadoMio.m3u8'"
            :poster="src + '/m3u8/bg.jpg'"
            controls
            :loop="true"
            :volume="0.6"
            width="355px"
            height="266px"
            class="flex justify-center mx-[10px] mt-[44px]"
          />

        </div>
        <ZonePay />  
    </div>
</template>


<script>
import { useI18n } from 'vue-i18n'
import topBox from "@/components/computer/TopBox.vue"
// import mainMenu from "@/components/computer/MainMenu.vue"
import ZonePay from "@/components/computer/PayZone.vue"
import axios from 'axios'
import { layer } from "vue3-layer"

import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'



export default {
    components: {
        topBox,
        // mainMenu,
        // videoPlay,
        VideoPlayer,
        ZonePay,
    },
    setup() {
        // console.log( `token : ${localStorage.getItem('token')}` )
        // if ( !localStorage.getItem('token') ) {
        //     console.log('连令牌都没有，还不去登录？')
        // }
    },
    data() {
        return {
          src: process.env.VUE_APP_VIDEO,
          title: this.$t('page.PublishArticle'),
          // uploadProgress属性，用于存储上传进度。
          uploadProgress: 0,
        }
    },
    mounted() {
      console.log( this.src )
        // this.playCreate()
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // 通过 Token 去调取帐号相关信息，即时。
        // this.getInfo()
        return {}
    },
    methods: {
        onUse(type) {
          if (type == 'demux') {
            this.config.demuxUseWorker = !this.config.demuxUseWorker
          } else if (type == 'band') {
            this.config.showBandwidth = !this.config.showBandwidth
          } else if (type == 'hasAudio') {
            this.config.hasAudio = !this.config.hasAudio
          } else {
            this.config.useMSE = false
            this.config.useSIMD = false
            this.config.useWCS = false
            if (type == 'useMSE') this.config.useMSE = true
            if (type == 'useSIMD') this.config.useSIMD = true
            if (type == 'useWCS') this.config.useWCS = true
          }
          if (this.isPlay) {
            this.onDestroy().then(() => {
              this.playCreate();
              this.onPlayer()
            });
          }
        },
        setFullscreen() {
          this.playerInfo.setFullscreen(true)
        },
        onPause() {
          this.playerInfo.pause()
        },
        onMute() {
          this.playerInfo.mute()
        },
        onPlayer() {
          this.isPlay = true
          setTimeout((url) => {
            this.playerInfo && this.playerInfo.play(url).then(() => {
            }).catch((e) => {
              console.error(e);
            });
          }, 0, this.videoUrl)
        },
        onPlayerPlayback() {
          setTimeout((url) => {
              this.playerInfo && this.playerInfo.playback(url, {
                controlType: "simple",
                showRateBtn: true,
                showControl: true,
                isUseLocalCalculateTime: true, // 是否使用本地时间来计算playback时间
                playbackPause: false,
                useMSE: true,
                rateConfig: [
                  { label: "正常", value: 1 },
                  { label: "2倍", value: 2 },
                  { label: "4倍", value: 4 },
                  { label: "8倍", value: 8 },
                  { label: "16倍", value: 16 },
                ],
              }).then(() => {

              }).catch((e) => {
                console.error(e);
              });
          }, 0, this.videoUrl)
        },
        onStop() {
          this.isPlay = false
          this.onDestroy().then(() => {
            this.playCreate();
          });
        },
        onDestroy() {
            // eslint-disable-next-line
            let _this = this
            // eslint-disable-next-line
            return new Promise((resolve, reject) => {
                if (this.playerInfo) {
                this.playerInfo.destroy()
                this.playerInfo = null
                }
                setTimeout(() => {
                resolve();
                }, 100);
            })
        },
        // eslint-disable-next-line
        onReplay(type) {
          this.onDestroy().then(() => {
            this.playCreate();
            this.onPlayer()
          });
        },
        playCreate() {
          var container = document.getElementById('player_box1');
          // eslint-disable-next-line
          var easyplayer = new EasyPlayerPro({
            container: container,
            decoder: './js/decoder-pro.js',
            videoBuffer: 0.2, // 缓存时长
            isResize: false,
            text: "",
            loadingText: "加载中",
            // debug: true,
            // debugLevel: 'debug',
            useMSE: true,
            // eslint-disable-next-line
            useSIMD: false,
            useWCS: true,
            isMulti: true,
            // eslint-disable-next-line
            useSIMD: this.config.useSIMD,
            hasAudio: this.config.hasAudio,
            showBandwidth: this.config.showBandwidth, // 显示网速
            showPerformance: this.config.showBandwidth,
            operateBtns: {
              fullscreen: true,
              screenshot: true,
              play: true,
              audio: true,
              record: true,
              quality: true,
              performance: true,
            },
            watermarkConfig: {
              text: {
                content: 'easyplayer-pro'
              },
              right: 10,
              top: 10
            },
            playbackForwardMaxRateDecodeIFrame: 1,
            isWebrtcForOthers:true,
            demuxUseWorker: true,
            supportHls265:true,
          });

          easyplayer.on("fullscreen", function (flag) {
            // eslint-disable-next-line
            console.log('is fullscreen', id, flag)
          })
          easyplayer.on('playbackPreRateChange', (rate) => {
            easyplayer.forward(rate);
          })

          easyplayer.on('playbackSeek', (data) => {
            easyplayer.setPlaybackStartTime(data.ts);
          })
          this.playerInfo = easyplayer
        },
        




        doClick() {
            // console.log('ok')
        },
        onSubmit() {
            this.$route.to('/recharge')
        },
        // 得到这个用户的基本信息
        getInfo()
        {
            // console.log('得到这个用户的基本信息')
            axios.post(
                '/api/m/info/user', 
                {
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response.data)
                if (0===response.data.code) {
                    this.api.bank_id = response.data.data.bank_id
                    this.api.account = response.data.data.account
                    this.api.grade = `VIP${response.data.data.grade}` 
                    this.api.uid = response.data.data.uid
                    this.api.invite = 999999 - response.data.data.uid
                    this.api.balance = response.data.data.balance
                    this.api.frozen = response.data.data.frozen
                } else {
                    // let word = response.data.data.id
                    // let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // // 转成国际化 后端转提示语 前端转表单名称
                    // let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        // End 得到这个用户的基本信息
        out() {
            // localStorage.removeItem('token')
            axios.post(
                '/api/m/out', 
                {

                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    localStorage.removeItem('token')
                    // this.$router.go(0) // 刷新本页
                    // 跳转
                    this.$router.push({
                        path: '/computer/login',
                        query: {
                            msg: 'account out'
                        }
                    })
                } else {
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }, // End out() 退出系统
        // 提交视频及附属信息
        runSubmit() {
            console.log('提交视频及附属信息')

            // 正在提交中，请等待...
            axios.post(
                '/api/m/video', 
                {
                  video: this.form.video,
                },
                {
                  headers: {
                    'Content-Type': 'multipart/form-data', // multipart/form-data，以支持文件上传
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept-Language': this.lang,
                    'token': localStorage.getItem('token')
                  },
                  
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    // localStorage.removeItem('token')
                    // this.$router.go(0) // 刷新本页
                    // 跳转
                    // this.$router.push({
                    //     path: '/computer/login',
                    //     query: {
                    //         msg: 'account out'
                    //     }
                    // })
                } else {
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })

        }, // End runSubmit() 提交视频及附属信息
    }
}
</script>

<style>
.focus {
    background-image: url('/public/upload/600.png');
    background-repeat: no-repeat; /** 图片不要平铺 */
    background-size: 100% 190px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
.ql-toolbar {
    @apply p-[1px];
    height: auto;
    font-size: 12px;
}


  /* color: #07baf4; */
  * {
    margin: 0;
    padding: 0;
  }

  p {
    line-height: 24px;
  }



  .radio-container {
    padding: 10px 0;
  }

  .radio-item {
    cursor: pointer;
    display: inline-block;
    padding: 6px 12px;
    margin-right: 15px;
    border-radius: 4px;
    border: 1px #ccc solid;
  }

  .radio-active {
    color: #fff;
    background-color: #07baf4;
    border-color: #07baf4;
  }

  .player_container {
    display: grid;
  }

  .player_container_1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .player_item {
    position: relative;
    padding-bottom: 56%;
    background-color: #000;
    border: 1px #fff solid;
  }

  .inputs {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 36px;
    line-height: 36px;
    outline: none;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;
    max-width: 600px;
    margin-right: 16px;
  }

  .player_box {
    height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .df {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .df span {
    margin-left: 4px;
  }

  .df form {
    margin-right: 4px;
  }
</style>