<template>
    <div class="computerBox">
        <!-- 固定到顶部加上 fixed top-0  -->
        <div class="h-[44px] z-50 w-[375px]">
            <div class="flex justify-between items-center px-[16px] py-[7px] h-[30px] w-[375px]">
                <div class="text-ms w-[30px] h-[30px] py-[7px]">
                    <router-link to="/">
                        <svg class="w-[30px] h-[30px]"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="#283643" d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z"/>
                        </svg>
                    </router-link>
                </div>
                <div class="text-ms w-[30px] h-[30px] py-[7px]">
                    
                </div>
            </div>
        </div>
        <!-- 内容区 -->
        <div class="px-[16px] w-[375px]">

            <div class="flex justify-center mt-[90px]">
                <img src="https://tdkt06.com/img/login.ef1c71dc.png" width="100px" height="100px" />
            </div>
            <main class="">
                <div class="">
                    <div class="my-3">
                        <h1 class="text-m14 font-semibold text-slate-900 text-left mb-[10px]">{{ $t('title.account') }}</h1>
                        <input
                            type="text"
                            class="block w-full px-5  py-[15px] border rounded-full bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-ms"
                            :placeholder="$t('placeholder.account')"
                            v-model="input.account"
                        />
                    </div>
                    <div class="my-2">
                        <h1 class="text-m14 font-semibold text-slate-900 text-left mb-[10px]">{{ $t('title.password') }}</h1>
                        <input
                            type="password"
                            class="block w-full px-5 py-[15px] border rounded-full bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1 focus:outline-none  text-ms"
                            :placeholder="$t('placeholder.password')"
                            v-model="input.password"
                        />
                    </div>
                </div>
                
            </main>
            <div class="text-m14">
                <router-link to="/computer/register">
                    <div class="color-y">{{ $t('but.register') }}</div>
                </router-link>
            </div>

                <div class="mt-0 p-4">
                    <button
                        class="block w-full px-5  py-[15px] border rounded-full bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring-1  focus:outline-none text-ms"
                        x-bind:disabled="!isValid"
                        @click="onSubmit"
                    >
                        {{ $t('but.login') }}
                    </button>
                </div>

        </div>
        <mainMenu />
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
// import topMenu from "@/components/computer/TopMenu.vue"
import mainMenu from "@/components/computer/MainMenu.vue"
import axios from 'axios'
import { layer } from "vue3-layer"
export default {
    components: {
        // topMenu,
        mainMenu
    },
    data() {
        return {
            lang: '',
            input: {
                account: '',
                password: '',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        return {}
    },
    methods: {
        onSubmit() {
            // console.log('登录')
            axios.post(
                '/api/login', 
                {
                    account: this.input.account,
                    passkey: this.input.password
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response.data)
                if (0===response.data.code) {
                    localStorage.setItem('token', response.data.data.token)
                    localStorage.setItem('isLogin', 1)
                    // 跳转
                    this.$router.push({
                        path: '/computer/user',
                        query: {
                            msg: 'reg account'
                        }
                    })
                } else {
                    // let word = response.data.data.id
                    // let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    // let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>

    .computerBox {
        background: url('https://tdkt06.com/img/headbg2x.1c5aca6e.png') 0% 0% / 100% no-repeat;
    }

    #loginBox {
        // background: linear-gradient(270deg, #f7395f 0%, #fe702e 100%); 
        // background-image: url('/public/upload/6x4.png');
        // background-repeat: no-repeat;
    }

    .form-select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%239ca3af' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
        background-size: 1.5em 1.5em;
        -webkit-tap-highlight-color: transparent;
      }

      .submit-button:disabled {
        cursor: not-allowed;
        background-color: #D1D5DB;
        color: #111827;
      }

      .submit-button:disabled:hover {
        background-color: #9CA3AF;
      }

      .credit-card {
        max-width: 420px;
        margin-top: 3rem;
      }

      @media only screen and (max-width: 420px)  {
        .credit-card .front {
          font-size: 100%;
          padding: 0 2rem;
          bottom: 2rem !important;
        }

        .credit-card .front .number {
          margin-bottom: 0.5rem !important;
        }
      }

</style>