<template>
    <!-- 固定到顶部加上 fixed top-0  -->
    <div class="pcTopBox grid grid-cols-6">
        <div class="">
            <router-link to="#" @click="$router.back()">
                <svg class="w-[24px] h-[24px] m-[10px] mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="#FFFFFF" d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zM142.1 273l135.5 135.5c9.4 9.4 24.6 9.4 33.9 0l17-17c9.4-9.4 9.4-24.6 0-33.9L226.9 256l101.6-101.6c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L142.1 239c-9.4 9.4-9.4 24.6 0 34z"/>
                </svg>
            </router-link>
        </div>
        <div class="col-span-4 flex items-center justify-center">
            <h1 class="text-[14px]">{{ title }}</h1>
        </div>
        <div class="">
            <svg class="w-[24px] h-[24px] m-[10px] mx-auto" @click="shortcutKey" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="#FFFFFF" d="M495 225.1l-17.2 1.1c-5.3-39.5-20.8-75.6-43.9-105.8l13-11.4c6.9-6.1 7.3-16.8 .7-23.3L426.4 64.4c-6.5-6.5-17.2-6.2-23.3 .7L391.7 78.1c-30.2-23.1-66.4-38.6-105.8-43.9L286.9 17c.6-9.2-6.7-17-16-17h-29.9c-9.2 0-16.5 7.8-16 17l1.1 17.2c-39.5 5.3-75.6 20.8-105.8 43.9l-11.4-13c-6.1-6.9-16.8-7.3-23.3-.7L64.4 85.6c-6.5 6.5-6.2 17.2 .7 23.3l13 11.4c-23.1 30.2-38.6 66.4-43.9 105.8L17 225.1c-9.2-.6-17 6.7-17 16v29.9c0 9.2 7.8 16.5 17 16l17.2-1.1c5.3 39.5 20.8 75.6 43.9 105.8l-13 11.4c-6.9 6.1-7.3 16.8-.7 23.3l21.2 21.2c6.5 6.5 17.2 6.2 23.3-.7l11.4-13c30.2 23.1 66.4 38.6 105.8 43.9L225.1 495c-.6 9.2 6.7 17 16 17h29.9c9.2 0 16.5-7.8 16-17l-1.1-17.2c39.5-5.3 75.6-20.8 105.8-43.9l11.4 13c6.1 6.9 16.8 7.3 23.3 .7l21.2-21.2c6.5-6.5 6.2-17.2-.7-23.3l-13-11.4c23.1-30.2 38.6-66.4 43.9-105.8l17.2 1.1c9.2 .6 17-6.7 17-16v-29.9c0-9.2-7.8-16.5-17-16zM281.8 98.6c24.8 4.1 47.6 13.7 67.2 27.8l-42.6 48.3c-8.7-5.4-18.3-9.5-28.6-12l4-64.1zm-51.7 0l4 64.1c-10.3 2.4-19.9 6.5-28.6 12l-42.6-48.3c19.6-14.1 42.4-23.7 67.2-27.8zm-103.8 64.3l48.3 42.6c-5.4 8.7-9.5 18.3-12 28.6l-64.1-4c4.1-24.8 13.7-47.6 27.8-67.2zm-27.8 118.9l64.1-4c2.4 10.3 6.5 19.9 12 28.6l-48.3 42.6c-14.1-19.6-23.7-42.4-27.8-67.2zm131.6 131.6c-24.8-4.1-47.6-13.7-67.2-27.8l42.6-48.3c8.7 5.4 18.3 9.5 28.6 12l-4 64.1zM256 288c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm25.8 125.4l-4-64.1c10.3-2.4 19.9-6.5 28.6-12l42.6 48.3c-19.6 14.1-42.4 23.7-67.2 27.8zm103.8-64.3l-48.3-42.6c5.4-8.7 9.5-18.3 12-28.6l64.1 4c-4.1 24.8-13.7 47.6-27.8 67.2zm-36.3-114.9c-2.4-10.3-6.5-19.9-12-28.6l48.3-42.6c14.1 19.6 23.7 42.4 27.8 67.2l-64.1 4z"/>
            </svg>
        </div>

        <!-- 帮助提示的弹出层 自定义大小 -->
        <!-- 点击遮罩层关闭 :shadeClose="true"  -->
        <!-- 不要确定按钮 :type="1" -->
        <!-- 从左侧滑入 :anim="3" -->
        <s3-layer v-model="show.odds" title="快捷菜单" :area="['300px', '460px']" :offset="['44px', '75px']" :type="1" :shadeClose="true" :anim="3" class="text-slate-700">
            赔率最高只能设置到 100 单位是% <br />
            表示按投注 1:1 赔付 <br />
            平台扣掉 0.1% 即千分之一平台服务费 <br />
            例如投注 100 最终给的赔付是 99.9 <br />
            <br />
            服务邮箱 email : <br />
            vinson.james.911@gmail.com <br />
            <br />
            客服在线<br />
        </s3-layer>



    </div>
</template>
<script>
import { ref } from "vue"
export default {
    //接收参数
    props: { 
        title: String 
    },
    data() {
        return {
            show: {
                odds: ref(false), // 帮助提示
            },
        }
    },
    methods: {
        shortcutKey() {
            this.show.odds = true
        },
    }
}
</script>

<style>

</style>