<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] ">
            <div class="w-[calc(100vw-12vw)] mx-auto  text-black">
                <!-- center-->
                <div class="container mx-auto">

                    <input type="radio" name="tab" id="tab1" class="hidden" checked>
                    <input type="radio" name="tab" id="tab2" class="hidden">
                    <input type="radio" name="tab" id="tab3" class="hidden">
                    <input type="radio" name="tab" id="tab4" class="hidden">

                    <div class="nav-tab fixed left-0 top-[50px] h-[50px] w-full z-10 text-xs-xs z-10 bg-[#FFFFFF] ">
                        <div class="mx-auto pt-2">
                            <label for="tab1">
                                <span class="py-1 px-2 rounded-full inline-block">{{ $t('pageTxt.order.tab1') }}</span>
                            </label>
                            <label for="tab2">
                                <span class="py-1 px-2 rounded-full inline-block">{{ $t('pageTxt.order.tab2') }}</span>
                            </label>
                            <label for="tab3">
                                <span class="py-1 px-2 rounded-full inline-block">{{ $t('pageTxt.order.tab3') }}</span>
                            </label>
                            <label for="tab4">
                                <span class="py-1 px-2 rounded-full inline-block">{{ $t('pageTxt.order.tab4') }}</span>
                            </label>
                        </div>
                    </div>

                    <!-- List A-1 -->
                    <div class="tab1 mt-[50px] mb-[50px] pb-[50px]  hidden overflow-y-auto">
                        
                        <!-- <div aria-label="group of cards" tabindex="0" class="focus:outline-none py-1 w-full">
                            <div class="lg:flex items-center justify-center w-full">
                                
                                <div tabindex="0" aria-label="card 1" class="focus:outline-none lg:w-4/12 lg:mr-7 lg:mb-0 mb-1 bg-white p-1 shadow rounded">
                                    xxxx
                                </div>
                            </div>
                            <div class="lg:flex items-center justify-center w-full">
                                
                                <div tabindex="0" aria-label="card 1" class="focus:outline-none lg:w-4/12 lg:mr-7 lg:mb-0 mb-1 bg-white p-1 shadow rounded">
                                    xxxx
                                </div>
                            </div>
                            <div class="lg:flex items-center justify-center w-full">
                                
                                <div tabindex="0" aria-label="card 1" class="focus:outline-none lg:w-4/12 lg:mr-7 lg:mb-0 mb-1 bg-white p-1 shadow rounded">
                                    xxxx
                                </div>
                            </div>
                        </div> -->
                        <ul class="w-full">
                       
                            <li v-for="item in pageData" :key="item.orders_id"  class="flex justify-between gap-x-6 py-5" >
                                <div class="flex min-w-0 gap-x-4" 
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                     }})"
                                >
                                    <img v-if="item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" :src="item.commodity.icon" alt="">
                                    <img v-if="!item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" src="/img/clocks.png" alt="">
                                </div>
                                <div class="shrink-0 sm:flex sm:flex-col sm:items-end" 
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                     }})"
                                >
                                    <p v-if="item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">
                                        {{ item.commodity.title_vi }}
                                    </p>
                                    <p v-show="!item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">{{ $t('pageTxt.order.awaiting') }}</p>

                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"><span class="text-[#fe782f]">{{ item.amount }} VND</span> </p>
                                    <p class="text-xs-xs leading-1 text-gray-400 text-right">
                                        <svg v-show="0==item.status" class="w-3.5 h-3.5 me-2 text-orange-400 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <svg v-show="2==item.status" class="w-3.5 h-3.5 me-2 text-rose-600 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <svg v-show="3==item.status" class="w-3.5 h-3.5 me-2 text-sky-400 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <svg v-show="4==item.status" class="w-3.5 h-3.5 me-2 text-green-500 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <time>{{item.number}}</time>
                                    </p>
                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"><time>{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</time></p>
                                </div>
                            </li>
                       
                        </ul>
                    </div><!-- /End List A-1 -->

                    <!-- List A-2 -->
                    <div class="tab2 mt-[50px] mb-[50px] pb-[50px]  hidden overflow-y-auto">
                        <ul class="w-full">
                            <li v-for="item in pageData" :key="item.orders_id" v-show="2==item.status" class="flex justify-between gap-x-6 py-5" >
                                <div class="flex min-w-0 gap-x-4"
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                     }})"
                                >
                                    <img v-if="item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" :src="item.commodity.icon" alt="">
                                    <img v-if="!item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" src="/img/clocks.png" alt="">
                                </div>
                                <div class="shrink-0 sm:flex sm:flex-col sm:items-end"
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                    }})"
                                >
                                    <p v-if="item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">
                                        {{ item.commodity.title_vi }}
                                    </p>
                                    <p v-show="!item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">{{ $t('pageTxt.order.awaiting') }}</p>

                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"> <span class="text-[#fe782f]">VND</span> </p>
                                    <p class="text-xs-xs leading-1 text-gray-400 text-right">
                                        <svg v-show="2==item.status" class="w-3.5 h-3.5 me-2 text-rose-600 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <time>{{item.number}}</time></p>
                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"><time>{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</time></p>
                                </div>
                            </li>
                        </ul>
                    </div><!-- /End List A-2 -->

                    <div class="tab3 mt-[50px] mb-[50px] pb-[50px]  hidden overflow-y-auto">
                        <!-- List A-3 -->
                        <ul class="w-full">
                            <li v-for="item in pageData" :key="item.orders_id" v-show="4==item.status" class="flex justify-between gap-x-6 py-5" >
                                <div class="flex min-w-0 gap-x-4"
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                    }})"
                                >
                                    <img v-if="item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" :src="item.commodity.icon" alt="">
                                    <img v-if="!item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" src="/img/clocks.png" alt="">
                                </div>
                                <div class="shrink-0 sm:flex sm:flex-col sm:items-end"
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                    }})"
                                >
                                    <p v-if="item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">
                                        {{ item.commodity.title_vi }}
                                    </p>
                                    <p v-show="!item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">{{ $t('pageTxt.order.awaiting') }}</p>

                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"> <span class="text-[#fe782f]">VND</span> </p>
                                    <p class="text-xs-xs leading-1 text-gray-400 text-right">
                                        <svg v-show="4==item.status" class="w-3.5 h-3.5 me-2 text-green-500 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <time>{{item.number}}</time></p>
                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"><time>{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</time></p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="tab4 mt-[50px] mb-[50px] pb-[50px]  hidden overflow-y-auto">
                        <!-- List A-4 -->
                        <ul class="w-full">
                            <li v-for="item in pageData" :key="item.orders_id" v-show="3==item.status" class="flex justify-between gap-x-6 py-5" >
                                <div class="flex min-w-0 gap-x-4"
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                    }})"
                                >
                                    <img v-if="item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" :src="item.commodity.icon" alt="">
                                    <img v-if="!item.commodity_id" class="h-16 w-16 flex-none rounded-full bg-gray-50" src="/img/clocks.png" alt="">
                                </div>
                                <div class="shrink-0 sm:flex sm:flex-col sm:items-end"
                                    @click="$router.push({ path: '/order/detail', query: { 
                                        orders_id: item.orders_id,
                                        status: item.status,
                                        number: item.number,
                                        date: item.created_at,
                                        amount: item.amount,
                                        commission: item.commission,
                                        commodity_id: item.commodity_id,
                                    }})"
                                >
                                    <p v-if="item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">
                                        {{ item.commodity.title_vi }}
                                    </p>
                                    <p v-show="!item.commodity_id" class="text-xs-xs text-right leading-1 text-gray-900">{{ $t('pageTxt.order.awaiting') }}</p>

                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"> <span class="text-[#fe782f]">VND</span> </p>
                                    <p class="text-xs-xs leading-1 text-gray-400 text-right">
                                        <svg v-show="3==item.status" class="w-3.5 h-3.5 me-2 text-sky-400 inline-block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <time>{{item.number}}</time></p>
                                    <p class="text-xs-xs leading-1 text-gray-500 text-right"><time>{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</time></p>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
                <!-- /end center-->
            </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
// import axios from 'axios'
// import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/mobile/MainMenu.vue"
import { ref } from 'vue'
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

export default {
    components: {
        topBox,
        mainMenu,
    },
    setup() {


        // 向子组件传递的参数
        const title = ref( 'x' )  
        const formatFunc = format()
        return {
            title,
            ...formatFunc,
        }
    },

    data() {
        return {
            lang: '',
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 15,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.order') // 重置 setup 中的 title 要在重设置了语言之后
        this.getData()
        return {}
    },
    methods: {
        getData()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&lang=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/page/order', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                console.log(res)
                // const data = toRaw(res)
                this.pageData = res.data.items
            })
        },  // End getData()...
    }   // End methods{...}
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}

</style>