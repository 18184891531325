<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] overflow-y-auto  bg-local">
            <div class="w-[calc(100vw-12vw)] mx-auto  text-black">
                <!-- main box -->
                <div class="my-10 text-left  text-xs text-slate-900">
                    <div class="relative flex flex-col text-gray-700 bg-white shadow-md  rounded-xl bg-clip-border">
                        <!-- /edit/password -->
                        
                        <nav class="flex min-w-[240px] flex-col gap-1 font-sans text-base font-normal text-blue-gray-700">

                            <router-link to="/edit/password">
                            <div role="button" class="flex indent-6 text-xs items-center w-full py-1 leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
                                {{ $t('revision.password') }}
                                <div class="grid ml-auto place-items-center justify-self-end">
                                    <button
                                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-blue-gray-500 transition-all hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    type="button">
                                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="w-3 h-3">
                                            <path fill-rule="evenodd"
                                                d="M136.6 138.8a64 64 0 0 0 -43.3 41.4L0 460l14.7 14.7L164.8 324.6c-3-6.3-4.8-13.2-4.8-20.6 0-26.5 21.5-48 48-48s48 21.5 48 48-21.5 48-48 48c-7.4 0-14.3-1.8-20.6-4.8L37.3 497.3 52 512l279.9-93.3a64 64 0 0 0 41.4-43.3L416 224 288 96l-151.4 42.8zm361.3-64.6l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </span>
                                    </button>
                                </div>
                            </div>
                            </router-link>

                            <div class="relative flex py-0 items-center">
                                <div class="flex-grow border-t ml-4 border-gray-300"></div>
                                <span class="flex-shrink mx-0 text-gray-400"></span>
                                <div class="flex-grow border-t mr-4 border-gray-300"></div>
                            </div>

                            <router-link to="/withdraw/password">
                            <div role="button" class="flex indent-6  text-xs items-center w-full  py-1   leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
                                {{ $t('revision.withdrawalPassword') }}
                                <div class="grid ml-auto place-items-center justify-self-end">
                                    <button
                                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-blue-gray-500 transition-all hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    type="button">
                                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="w-3 h-3">
                                            <path fill-rule="evenodd"
                                                d="M136.6 138.8a64 64 0 0 0 -43.3 41.4L0 460l14.7 14.7L164.8 324.6c-3-6.3-4.8-13.2-4.8-20.6 0-26.5 21.5-48 48-48s48 21.5 48 48-21.5 48-48 48c-7.4 0-14.3-1.8-20.6-4.8L37.3 497.3 52 512l279.9-93.3a64 64 0 0 0 41.4-43.3L416 224 288 96l-151.4 42.8zm361.3-64.6l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </span>
                                    </button>
                                </div>
                            </div>
                            </router-link>

                            <div class="relative flex py-0 items-center">
                                <div class="flex-grow border-t ml-4 border-gray-300"></div>
                                <span class="mx-0 text-gray-400"></span>
                                <div class="flex-grow border-t mr-4 border-gray-300"></div>
                            </div>

                            <router-link to="/withdraw/account">
                            <div role="button" class="flex indent-6 text-xs items-center w-full py-1  leading-tight transition-all rounded-lg outline-none text-start hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900">
                                {{ $t('revision.withdrawalAccount') }}
                                <div class="grid ml-auto place-items-center justify-self-end">
                                    <button
                                    class="relative h-10 max-h-[40px] w-10 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-blue-gray-500 transition-all hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                    type="button">
                                    <span class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="w-3 h-3">
                                            <path fill-rule="evenodd"
                                                d="M136.6 138.8a64 64 0 0 0 -43.3 41.4L0 460l14.7 14.7L164.8 324.6c-3-6.3-4.8-13.2-4.8-20.6 0-26.5 21.5-48 48-48s48 21.5 48 48-21.5 48-48 48c-7.4 0-14.3-1.8-20.6-4.8L37.3 497.3 52 512l279.9-93.3a64 64 0 0 0 41.4-43.3L416 224 288 96l-151.4 42.8zm361.3-64.6l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"
                                                clip-rule="evenodd"></path>
                                        </svg>
                                    </span>
                                    </button>
                                </div>
                            </div>
                            </router-link>

                        </nav>
                    </div>
                </div>

                <div class="mt-0 p-4">
                      
                      <button
                          class="submit-button px-1 py-1 rounded-full bg-orange-500 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
                          x-bind:disabled="!isValid"
                          x-on:click="onSubmit()"
                      >
                          {{ $t('but.continue') }}
                      </button>
               
              </div>
                <!-- /end main box -->
            </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/mobile/MainMenu.vue"
import { ref } from 'vue'

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: ''
        }
    },
    mounted() {
        
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.revision') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    }
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
</style>