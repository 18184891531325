<template>
    <div id="computer">
        <div class="h-[40px] relative">
            <img class="cp-top" src="@/assets/img/head.png" />
            <div class="absolute right-0 top-0 w-4/12 h-[40px] ">
                <div class="flex py-[5px] float-right" @click="toggleDrop">
                    <img class="w-[30px] h-[30px] rounded-full border-1 border-gray-50" :src="'/img/'+`${lang}`+'.jpg'"  />
                    <div class="text-xs-xs m-[5px]">{{ $t('lang.'+lang) }}</div>
                </div>
                <!-- 弹出用户帐号快捷菜单 -->
                <div v-show="showDropDown" class="absolute z-10 w-[120px] right-0 top-[40px] origin-top-right rounded-md bg-white shadow-lg ring-0 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1 text-left" role="none">
                        <ul>
                            <li v-for="(value, key) in languages" v-bind:key="key" class="text-xs-xs mx-2 my-1.5">
                                <button  @click="switchLang(key)">{{  value  }}</button>
                            </li>
                        </ul>
                  </div>
                </div><!-- /End 弹出用户帐号快捷菜单 -->
            </div>
        </div>

        <!-- swiper -->
        <div class="swiper">
            <swiper
                :slidesPerView="1"
                :spaceBetween="30"
                :loop="true"
                :centeredSlides="true"
                :pagination="{ clickable: true }"
                :autoplay="{ delay: 3000, disableOnInteraction: false}"
                :navigation="false"
                :modules="modules"
            >
                <swiper-slide><img src="/upload/4ab790e14459500d.png" alt="" /></swiper-slide>
                <swiper-slide><img src="/upload/779a5fc5897127a6.png" alt="" /></swiper-slide>
                <swiper-slide><img src="/upload/a9a212de5251be43.png" alt="" /></swiper-slide>
            </swiper>
            <div class="pagination"></div>
            <div class="swiper-scrollbar"></div>
        </div><!-- /end swiper -->


        <!-- but 2 -->
        <div class="btn-box" v-if="!isLogin">
           <div class="btn" @click="$router.push({ path: '/computer/login', query: {}})">{{ $t('but.login') }}</div>
           <div class="btn" @click="$router.push({ path: '/computer/register', query: {}})">{{ $t('but.register') }}</div>
       </div><!-- /end but 2 -->


       <div class="btn" @click="$router.push({ path: '/computer/play/video', query: {}})">测试播放视频</div>
       

       
       <Carousel />
       <Carousel />
       <Carousel />
       <Carousel />
       <Carousel />
       <Carousel />


        <div class="clear-both">

    
            <!-- <div class="grid grid-cols-2 gap-2 pb-2">

                <div class="px-1"
                        v-for="(item, index) in api.commodity"
                        :key="item.commodity_id"
                    >
                    <div :class="index % 2 ? 'pr-2 pt-2' : 'pl-2 pt-2'">
                        <img :src="`${item.icon}`" alt="" />
                    </div>
                    <div class="text-xs-xs pt-[3px]">
                        {{ item.name }} {{ item.commodity_id }}
                    </div>
                </div>

            </div> -->

            <marquee />


            <div class="text-ms pageTitle flex justify-center">
                Đối tác
            </div>
            <div class="grid grid-cols-3 gap-1 mx-1 pb-[85px]">
                <div class="h-[69px]">
                    <img src="/img/1.6e3caa45.png" alt="" />
                </div>
                <div class="">
                    <img src="/img/2.2a833197.png" alt="" />
                </div>
                <div class="">
                    <img src="/img/3.19375762.png" alt="" />
                </div>
                <div class="h-[69px]">
                    <img src="/img/4.png" alt="" />
                </div>
                <div class="">
                    <img src="/img/5.fafbf248.png" alt="" />
                </div>
                <div class="">
                    <img src="/img/6.fdc05ab3.png" alt="" />
                </div>
            </div>

        </div>

        <mainMenu />
    </div>
</template>

<script>
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
// import { ref } from 'vue'
// 轮播图
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue"
// Import Swiper styles
// 引入swiper样式，对应css 如果使用less或者css只需要把scss改为对应的即可
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
// setup 语法糖只需要这样创建一个变量就可以正常使用分页器和对应功能，如果没有这个数组则无法使用对应功能
import { Autoplay, Navigation, Pagination } from "swiper/modules"

import marquee from "@/components/SimpleMarqueePc.vue"
import mainMenu from "@/components/computer/MainMenu.vue"
import postPages from "@/assets/js/postPages"
import Carousel from '@/components/CarouselList'

export default {
    components: {
        //   marquee,
        Swiper,
        SwiperSlide,
        marquee,
        mainMenu,
        Carousel
    },
    setup() {
        const onSlideChange = () => {
            console.log("slide change");
        }
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : false
        const isLogin = localStorage.getItem('isLogin') ? localStorage.getItem('isLogin') : 0
        return {
            onSlideChange,
            modules: [Autoplay, Navigation, Pagination],
            token,
            isLogin
        }
    },
    data() {
          return {
              lang: '',
              languages: {
                  'vi-vn': 'Tiếng Việt',
                  'zh-cn': '中文',
              },
              showDropDown: false,
              slide: 0,
              api: {
                commodity: [],
              },
   
          }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // 初始化页面的数据
        this.getData()
        
        return {}
    },
    methods: {
          // 用户点击，触发切换语言请求。
          // 记录下用户选择的语言，路由跳转一下。
          switchLang(lang) {
              localStorage.setItem('lang', lang) // 存储
              return this.$router.go(0) // 刷新本页
          },
          toggleDrop() {
              // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
              this.showDropDown = !this.showDropDown
          },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&lang=${this.lang}`

            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/commodity6', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {
                    this.api.commodity = res.data.items
                } else {
                    layer.msg(res.msg)
                }
            })
        }, // End getData()
        
        
    }
}
</script>

<!-- <style scoped src="../../public/css/main.min.css"></style> -->
<style lang="scss" scoped>
    .top-box-bg {
        background-image: url('/public/upload/600x55.png');
        background-repeat: no-repeat; /** 图片不要平铺 */
        background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
    }
    // .maxW {max-width: 600px;}
</style>